<template>
  <v-container fluid class="px-8">
    <v-card flat class="mb-3">
      <v-card-title>
        <v-select
          v-model="selectedFileId"
          :items="availableFiles"
          label="Available Files"
          item-value="fileId"
          class="test-locator-selectedFileId"
        >
          <template v-slot:selection="{ item }">
            <v-avatar size="16px" class="mx-2">
              <v-img :src="item.$calculated.icon" contain />
            </v-avatar>
            <span>{{ item.title }} ({{ item.fileName }})</span>
          </template>
          <template v-slot:item="{ item }">
            <v-avatar size="16px" class="mx-2">
              <v-img :src="item.$calculated.icon" contain />
            </v-avatar>
            <span :label="item.fileName"
              >{{ item.title }} ({{ item.fileName }})</span
            >
          </template>
        </v-select>
        <v-spacer></v-spacer>

        <v-btn
          class="ml-4 white--text"
          small
          color="blue"
          @click="attachFile"
          :disabled="selectedFileId == null"
        >
          <v-icon left small>mdi-plus</v-icon>
          {{ $t("attach") }}
        </v-btn>
      </v-card-title>
    </v-card>
    <v-card>
      <v-data-table :headers="headers" :items="attachedFiles">
        <template v-slot:item.fileName="{ item }">
          <div>
            <v-avatar size="16px" class="mx-2">
              <v-img :src="item.$calculated.icon" contain />
            </v-avatar>
            <span>{{ item.title }} ({{ item.fileName }})</span>
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="deAttachFile(item)"
            >mdi-delete</v-icon
          >
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;

export default {
  name: "GsTemplateFilesEditor",
  data() {
    return {
      templateId: null,
      files: [],
      attachedFiles: [],
      selectedFileId: null,
      headers: [
        { text: this.$t("fileName"), align: "start", value: "fileName" },
        { text: this.$t("actions"), value: "actions" }
      ]
    };
  },
  computed: {
    availableFiles() {
      const attachedFileIds = this.attachedFiles.map(p => p.fileId);
      return this.files.filter(p => !attachedFileIds.includes(p.fileId));
    }
  },
  methods: {
    async edit(templateId) {
      this.templateId =
        typeof templateId == "string" ? templateId : templateId.templateId;
      await this.getFiles();
      await this.getAttachedFiles();
    },
    async getFiles() {
      try {
        const url = `${this.$store.state.email_manager_api}/files?sort=fileName:asc`;
        const result = await callAPI({
          url,
          method: "GET"
        });
        this.files = [];
        for (const file of result.data) {
          await this.calculateProperties(file);
          this.files.push(file);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAttachedFiles() {
      try {
        const url = `${this.$store.state.email_manager_api}/templates/${this.templateId}/files`;
        const result = await callAPI({ url, method: "GET" });
        const attachedFileIds = result.data.map(p => p.fileId);
        this.attachedFiles = this.files.filter(p =>
          attachedFileIds.includes(p.fileId)
        );
      } catch (error) {
        console.log(error);
      }
    },
    async calculateProperties(file) {
      if (!file.fileName) {
        return;
      }
      file.$calculated = file.$calculated || {};
      file.$calculated.fileNameExt = file.fileName.substr(
        file.fileName.lastIndexOf(".") + 1
      );
      try {
        file.$calculated.icon = require(`@/assets/file-type-icons/${file.$calculated.fileNameExt}.svg`);
      } catch {
        file.$calculated.icon = require("@/assets/file-type-icons/unknown.svg");
      }
    },
    async attachFile() {
      try {
        const url = `${this.$store.state.email_manager_api}/templates/${this.templateId}/files`;
        const result = await callAPI({
          url,
          method: "POST",
          data: {
            fileId: this.selectedFileId
          }
        });
        await this.getAttachedFiles();
        this.selectedFileId = null;
      } catch (error) {
        console.log(error);
      }
    },
    async deAttachFile(file) {
      try {
        const url = `${this.$store.state.email_manager_api}/templates/${this.templateId}/files/${file.fileId}`;
        const result = await callAPI({
          url,
          method: "DELETE"
        });
        await this.getAttachedFiles();
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>
