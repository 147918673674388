<template>
  <v-form ref="form" class="px-6">
    <v-row>
      <v-text-field
        :label="$t('name')"
        v-model="formData.name"
        type="text"
      ></v-text-field>
    </v-row>
    <v-row>
      <v-textarea
        :label="$t('description')"
        v-model="formData.description"
        rows="4"
        no-resize
        counter="1024"
        maxlength="1024"
      ></v-textarea>
    </v-row>
    <v-row>
      <v-text-field
        :label="$t('type')"
        v-model="formData.type"
        type="text"
      ></v-text-field>
    </v-row>
    <v-row>
      <GsLocaleSelector v-model="formData.locale" ref="localeSelector" />
    </v-row>
    <v-row>
      <v-text-field
        :label="$t('subject')"
        v-model="formData.subject"
        type="text"
      />
    </v-row>
    <v-row>
      <v-textarea
        :label="$t('text')"
        v-model="formData.text"
        rows="4"
        no-resize
      ></v-textarea>
    </v-row>
    <v-row>
      <v-textarea
        :label="$t('html')"
        v-model="formData.html"
        rows="4"
        no-resize
      ></v-textarea>
    </v-row>
    <v-row>
      <v-select
        :label="$t('status')"
        v-model="formData.status"
        :items="statuses"
        item-text="name"
        item-value="id"
      />
    </v-row>
    <v-row v-if="errorsText">
      <span class="subtitle-1 mb-4 red--text">{{ errorsText }}</span>
    </v-row>
    <v-row>
      <v-btn block dark class="mt-6 default-button" @click="save">{{
        $t("save")
      }}</v-btn>
    </v-row>
  </v-form>
</template>

<script>
const enums = require("@/app-data/template-enums.json");
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
import GsLocaleSelector from "@/components/GsLocaleSelector";

export default {
  name: "GsTemplateEditor",
  components: {
    GsLocaleSelector
  },
  props: {},
  data() {
    return {
      templateId: null,
      formData: {
        name: null,
        description: null,
        type: null,
        locale: null,
        subject: null,
        text: null,
        html: null,
        status: null
      },
      types: enums.type,
      statuses: enums.status.map(p => {
        return { id: p, name: p.substr(p.lastIndexOf(".") + 1) };
      }),
      templateFileName: null,
      errors: []
    };
  },
  computed: {
    errorsText() {
      if (this.errors.length == 0) {
        return null;
      }
      return `${this.$t("required_at_templates")}: ${this.errors.join(", ")}`;
    }
  },
  methods: {
    async create() {
      this.clearForm();
    },
    async edit(templateId) {
      this.clearForm();
      if (typeof templateId == "string") {
        this.formData = await this.getTemplate(templateId);
        this.templateId = templateId;
      } else {
        this.formData = JSON.parse(JSON.stringify(templateId));
        this.templateId = this.formData.templateId;
      }
    },
    async getTemplate(id) {
      try {
        const result = await callAPI({
          url: `${this.$store.state.email_manager_api}/templates/${id}`,
          method: "GET"
        });
        return result.data;
      } catch (error) {
        this.errors.push(error);
      }
    },
    validation() {
      this.errors = [];
      if (!this.formData.name) {
        this.errors.push(this.$t("name"));
      }
      if (!this.formData.type) {
        this.errors.push(this.$t("type"));
      }
      if (!this.formData.locale) {
        this.errors.push(this.$t("locale"));
      }
      if (!this.formData.subject) {
        this.errors.push(this.$t("subject"));
      }
      if (!this.formData.text) {
        this.errors.push(this.$t("text"));
      }
      if (!this.formData.html) {
        this.errors.push(this.$t("html"));
      }
      return this.errors.length == 0;
    },
    async save() {
      this.errors = [];
      if (!this.validation()) {
        return;
      }

      const url = this.formData.templateId
        ? `${this.$store.state.email_manager_api}/templates/${this.formData.templateId}`
        : `${this.$store.state.email_manager_api}/templates`;

      const method = this.formData.templateId ? "PATCH" : "POST";
      const data = Object.assign({}, this.formData);
      delete data.templateId;
      delete data.createdAt;
      delete data.updatedAt;
      delete data.ownedBy;
      delete data.createdBy;
      delete data.updatedBy;

      try {
        const result = await callAPI({ url, method, data });
        // console.log("PATCH result: ", result)
        this.$emit("saved", this.templateId);
        this.clearForm();
      } catch (error) {
        this.errors.push(error);
      }
    },
    clearForm() {
      this.formData = {
        name: null,
        description: null,
        type: null,
        locale: null,
        subject: null,
        text: null,
        html: null,
        status: "template.status.active"
      };
      this.errors = [];
      this.templateFileName = null;
    },
    templateFileName_change() {
      if (!this.templateFileName) {
        this.formData.template = "";
        return;
      }
      // Use the javascript reader object to load the contents of the file in the v-model prop
      const reader = new FileReader();
      reader.readAsText(this.templateFileName);
      reader.onload = () => {
        this.formData.template = reader.result;
      };
    }
  }
};
</script>
