<template>
  <v-select
    :items="localeItems"
    :label="$t('locale_label')"
    :value="value"
    @change="change"
    class="test-locator-locale"
  >
    <template v-slot:item="{ item }">
      <span :label="item.text">{{ item.text }}</span>
    </template>
  </v-select>
</template>

<script>
import { memory } from "ngt-frontend-core";
const locales = require("@/app-data/locale-enums.json");

export default {
  name: "LocaleSelector",
  props: {
    value: {
      type: String,
      default: null
    }
  },
  computed: {
    locales() {
      return locales;
    },
    localeItems() {
      return this.locales.map(p => ({ value: p, text: this.$t(p) }));
    }
  },
  methods: {
    change(locale) {
      this.$emit("input", locale);
    },
    reset() {
      memory.getItem("locale", locale => {
        const defaultLocale = locale || "en_US";
        this.change(defaultLocale);
      });
    }
  }
};
</script>
